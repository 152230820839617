import React from "react";
import {Row, Col, Container} from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import {Link} from "gatsby";

const AbusePolicy = () => {
  return (
    <>
      <PageWrapper >
        <SEO title="Cancellation Policy"></SEO>
        <div className="inner-banner pt-29 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h2 className="title gr-text-2 mb-9">
                    Use Restrictions
                  </h2>
                  <p className="gr-text-8 mb-13">
                    Last updated: January 19, 2021
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-8">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="10">
                <div className="px-lg-8 px-xl-3">
                  <div className="single-block mb-11">
                    <p className="gr-text-8">
                      Hundreds of thousands of teams use Camelo products. We are proud to give them a better way to work. We also recognize that however good the maker’s intentions, technology can amplify the ability to cause great harm. That’s why we’ve established this policy. We feel an ethical obligation to counter such harm: both in terms of dealing with instances where Camelo is used (and abused) to further such harm, and to state unequivocally that Camelo is not a safe haven for people who wish to commit such harm. If you have an account with any of our products, you can’t use them for any of the restricted purposes listed below. If we find out you are, we will take action.
                    </p>
                  </div>

                  <div className="single-block mb-11">
                    <h3 className="gr-text-6 font-weight-bold pb-3">Restricted purposes</h3>
                    <ul className="gr-text-8">
                      <li><strong>Violence, or threats thereof:</strong> If an activity qualifies as violent crime in the United States or where you live, you may not use Camelo products to plan, perpetrate, or threaten that activity.</li>
                      <li><strong>Child exploitation, sexualization, or abuse</strong>We don’t tolerate any activities that create, disseminate, or otherwise cause child abuse. Keep away and stop. Just stop.</li>
                      <li><strong>Doxing</strong>If you are using Camelo products to share other peoples’ private personal information for the purposes of harassment, we don’t want anything to do with you.</li>
                      <li><strong>Malware or spyware</strong>Code for good, not evil. If you are using our products to make or distribute anything that qualifies as malware or spyware — including remote user surveillance — begone.</li>
                      <li><strong>Phishing or otherwise attempting fraud</strong>It is not okay to lie about who you are or who you affiliate with to steal from, extort, or otherwise harm others.</li>
                      <li><strong>Spamming</strong>No one wants unsolicited commercial emails. We don’t tolerate folks (including their bots) using Camelo products for spamming purposes. If your emails don’t pass muster with CAN-SPAM or any other anti-spam law, it’s not allowed.</li>
                      <li><strong>Cybersquatting</strong>We don’t like username extortionists. If you purchase a Camelo product account in someone else’s name and then try to sell that account to them, you are cybersquatting. Cybersquatting accounts are subject to immediate cancelation.</li>
                      <li><strong>Infringing on intellectual property</strong>You can’t use Camelo products to make or disseminate work that uses the intellectual property of others beyond the bounds of fair use.</li>
                    </ul>

                    <p className="gr-text-8">
                      While our use restrictions are comprehensive, they can’t be exhaustive — it’s possible an offense could defy categorization, present for the first time, or illuminate a moral quandary we hadn’t yet considered. That said, we hope the overarching spirit is clear: Camelo is not to be harnessed for harm, whether mental, physical, personal or civic. Different points of view — philosophical, religious, and political — are welcome, but ideologies like white nationalism, or hate-fueled movements anchored by oppression, violence, abuse, extermination, or domination of one group over another, will not be accepted here.
                    </p>
                  </div>
                </div>

                <p>
                  <Link
                    to="/legal"
                    className="btn-link with-icon-left gr-text-blue gr-text-7 font-weight-bold mt-11"
                  >
                    <i className="icon icon-tail-left font-weight-bold"></i>
                      View All Policies
                    </Link>
                </p>
                <p className="text-muted gr-opacity-4 text-right pt-12"><small>Adapted from the Camelo open-source policies / CC BY 4.0</small></p>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default AbusePolicy;
